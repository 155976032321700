#withDraw {
  .content-box {
    padding-top: 64px;
    padding-bottom: 48px;
  }

  .contact-title {
    width: 100%;
    height: 50px;
    margin-bottom: 28px;

    h2 {
      font-weight: 800;
      font-size: 40px;
      line-height: 50px;
      color: $black;
      text-align: center;
    }
  }

  .withDraw-form {
    .box {
      background-color: $white;
      box-shadow: 0px 4px 4px rgba(0, 74, 188, 0.2);
      margin-bottom: 32px;
      /deep/ .title {
        font-size: 24px;
      }
    }

    /deep/ .form-button {
      text-align: center;
      margin-bottom: 40px;
    }

    .box-card-top {
      padding: 50px 32px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-card-verification {
      padding: 50px 32px 0;
    }

    .box-card-submit {
      padding: 20px 32px 50px;
      display: flex;
      .el-checkbox {
        margin-top: 12px;
      }
      a {
        color: #055bff;
      }
    }

    /deep/.alert {
      margin-top: 30px;
      p {
        font-size: 16px;
        color: #e91545;
        margin: 0 0 10px;
      }
      ul {
        padding-left: 15px;
        max-width: 90%;

        li {
          list-style: disc;
          padding-left: 1em;
          font-size: 16px;
          line-height: 1.44;

          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  /deep/ .el-form-item__label {
    text-transform: inherit;
  }

  /deep/ .el-form-item {
    margin-bottom: 20px;
  }

  /deep/ .el-form-item.is-required {
    .el-form-item__label {
      &::before {
        display: none;
      }

      &::after {
        content: '*';
        color: $light-red !important;
        margin-right: 4px;
      }
    }
  }
}

/deep/ .info-box {
  padding-left: 18px;

  li {
    color: #055bff;
    margin-bottom: 10px;
    list-style: decimal;
    padding-left: 1em;

    &::marker {
      color: #055bff;
    }
  }
}

.vl {
  width: 0;
  height: 524px;
  opacity: 0.25;
  @include animation-ease(fadeInFromNone);
}

.result-wrapper {
  margin-top: 35px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
}
.result-container {
  border-radius: 8px;
  background-color: #fff;
  max-width: 1479px;
  padding: 20px 40px 40px;
}

.policy-checkbox {
  display: flex;
  align-items: center;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 0.25;
  }
}

@media (max-width: 1440px) {
  #withDraw .withDraw-form .box-card-top {
    padding: 50px 8px 8px 32px;
  }
}

@media (max-width: 1204px) {
  .result-wrapper {
    padding: 10px;
    margin-top: 0px;
  }
}

@media (max-width: 1200px) {
  // #withDraw .withDraw_form .box_card_top .top {
  //   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  // }
  #withDraw .withDraw-form .box-card-top {
    padding: 50px 32px 32px;
    .top {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
  }

  #withDraw .withDraw-form /deep/ .box-card-bottom .form-bottom-row {
    .img-row,
    .form_row {
      float: none;
    }

    .img-row {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    .form_row {
      max-width: 100%;
    }

    .form-list {
      grid-template-columns: 47.5% 47.5%;
      column-gap: 5%;
    }
  }
  #withDraw .withDraw-form /deep/ .box-card-bottom #visaForm .img-row {
    margin: 0 auto 40px;
    text-align: center;

    .img1 {
      display: block;
    }
  }
  #withDraw .withDraw-form .box-card-top {
    flex-direction: column;
  }
  .vl {
    width: 700px;
    height: 0px;
  }
}

@media (max-width: 768px) {
  .vl {
    width: 500px;
  }

  .result-wrapper {
    padding: 20px;
  }
  .result-container {
    padding: 10px;
  }
}

@media (max-width: 550px) {
  #withDraw .withDraw-form .box-card-top {
    padding: 50px 24px 30px;
    .top {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    .alert {
      p {
        font-size: 14px;
        margin: 0 0 20px;
      }
      ul {
        padding-left: 30px;
        max-width: 100%;
        li {
          font-size: 12px;
        }
      }
    }
  }

  #withDraw .withDraw-form /deep/ .box-card-bottom {
    padding: 30px 24px;
    .form-bottom-row .form-list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .int_p {
        margin-bottom: 24px;
      }
    }
    #visaForm .img-row {
      text-align: left;

      .img1 {
        display: inline-block;
      }
    }
  }
  .vl {
    width: 300px;
  }

  #withDraw .withDraw-form .box-card-submit {
    flex-direction: column;
    text-align: center;
    .el-checkbox {
      margin-bottom: 12px;
    }
  }
}

@media (max-width: 370px) {
  .vl {
    width: 200px;
  }
}

// policy dialog
/deep/ .policy-dialog {
  li {
    list-style: disc;
    text-align: left;
    margin-bottom: 1rem;
    word-break: break-word;
  }
}

/deep/ .submit-btn.el-button {
  text-transform: inherit;
  border-radius: 20px;

  &.is-disabled,
  &.is-disabled:focus,
  &.is-disabled:hover {
    color: $black;
    font-weight: 800;
    border: 1px solid $black;
    background-color: #fff;
    opacity: 0.3;
  }
}

// checkbox
/deep/ .el-checkbox {
  .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border-color: $black;
  }

  .el-checkbox__inner {
    transform: scale(2);
    border-color: #d8d8d8;
    border-radius: 4px;
    margin-left: 8px;
  }

  .el-checkbox__input.is-checked,
  .el-checkbox__input.is-focus {
    .el-checkbox__inner {
      border-color: #d8d8d8;
    }
  }
}

// notice area
.notice-actions {
  display: flex;
  justify-content: space-between;

  &.one-btn {
    justify-content: center;
  }
}
