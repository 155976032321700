.l-select {
  position: relative;
}

.tag {
  position: absolute;
  z-index: 10;
  top: 0;
  @include rtl-sass-prop(left, right, 0);
  font-size: 10px;
  line-height: 16px;
  padding-left: 2px;
  width: 88px;
  height: 16px;
  @include rtl-sass-value(border-top-left-radius, 8px, 0px);
  @include rtl-sass-value(border-top-right-radius, 0px, 8px);
  @include rtl-sass-value(border-bottom-right-radius, 8px, 0px);
  @include rtl-sass-value(border-bottom-left-radius, 0px, 8px);
  background-color: #d9d9d9;
  color: $future-blue;
  text-align: center;
}

.el-select-dropdown__item {
  height: 60px;
}

.showPlaceholder {
  /deep/ {
    .is-disabled .el-input__inner,
    .el-input__inner {
      border: 1px solid #cccfd4;
      border-radius: 10px;
    }

    .is-focus .el-input__inner,
    .el-input__inner {
      height: 60px;
      font-size: 0;
      background-position: right 10px center;
    }
    .el-input__prefix,
    .el-input__suffix {
      color: #0051ff;
      width: 85%;
      top: 10%;
    }
    .el-input__prefix {
      .type-Img,
      .type-Img50 {
        float: left;
        @include rtl-sass-value(margin-left, 15px, 30px);
        margin-top: -5px;
      }
    }
    .is-disabled .el-input__prefix {
      color: #cccfd4;
    }
    .select-text {
      float: left;
      margin-top: 5px;
      @include rtl-sass-value(margin-left, 10px, 30px);
      p {
        // margin-top: 10px;
        // display: inline-block;
        margin-top: 4px;
        text-align: left;
        display: inline-block;
      }
      i {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .amount {
      float: right;
      margin-top: 5px;
      font-size: 16px;
      &.mr_amount {
        margin-top: 1px;
      }
    }
  }
}

.select-options-container {
  display: flex;
  justify-content: space-between;
}

.creditCard {
  /deep/ {
    .is-disabled .el-input__prefix {
      color: #0051ff;
    }
  }
  &.ccReset {
    /deep/ {
      .is-disabled .el-input__prefix {
        color: #cccfd4;
      }
    }
  }
}

.type-Img {
  padding: 5px;
  max-height: 54px;
  max-width: 102px;
  object-fit: contain;

  &.selected-img {
    height: 36px;
    // margin-top: 5px !important;
    margin-top: 8px !important;
  }
}

.type-Img50 {
  width: 50px;
  padding: 5px;
  margin-left: 15px;
}

.select-text {
  float: left;
  font-size: 14px;
}
.select_img {
  // float: right;
}

@media (max-width: 1440px) {
  .showPlaceholder {
    /deep/ {
      .select-text {
        max-width: 192px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .showPlaceholder {
    /deep/ {
      .select-text {
        max-width: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .showPlaceholder {
    /deep/ {
      .select-text {
        max-width: 200px;
      }
    }
  }
}

@media (max-width: 550px) {
  .showPlaceholder {
    /deep/ {
      .select-text {
        &.small-text {
          font-size: 12px;
          max-width: 100px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .showPlaceholder {
    /deep/ {
      .el-input__prefix {
        .type-Img,
        .type-Img50 {
          display: none;
        }
      }
    }
  }
}
