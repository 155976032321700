.credit-card-content-wrapper {
  margin-top: 28px;
  padding: 4px;
}
.credit-card-content {
  flex-wrap: wrap;
}

.card-sample {
  text-align: center;
  margin-top: 8px;
}

.expire-date {
  display: flex;
}
.month-select {
  width: 35%;
  margin-right: 16px;
}
.year-select {
  width: 55%;
}

/deep/ .el-button {
  text-transform: inherit;

  &.btn-blue.is-disabled {
    background-color: #fff !important;
  }
  & + .el-button {
    margin-left: 36px;
  }

  &:hover,
  &:active {
    &.is-disabled {
      color: #c0c4cc;
    }
  }

  &.white_btn {
    border-radius: 6px;
    border: 1px solid #000d28;
    background-color: unset;
    color: #000d28;
    margin-right: 10px;

    &:hover {
      color: #000d28;
    }

    &:focus {
      border: solid 1px #000d28;
      background-color: unset;
      color: #000d28;
    }
  }
}

.archive-action {
  margin-top: 30px;
}

.archive-upload {
  margin-top: 48px;

  /deep/ .el-upload__tip {
    margin: 0px;
  }
}

@media (min-width: 992px) {
  .archive-form {
    display: flex;

    &-upload {
      flex: 1 1 auto;

      & + & {
        margin-left: 24px;
      }
    }
  }
}
