
              @import "@/assets/css/variables.scss";
            


















































































































@import '@/assets/css/components/vUpload.scss';
.upload.el-form-item /deep/ {
  margin-bottom: 0 !important;
}
.upload-demo {
  background-color: #d8d8d8;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 36px;
    height: 36px;
  }

  .button-upload {
    margin-left: 0;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;
  }
}

/deep/ .el-upload {
  border: none;
}

.el-upload__tip {
  color: $future-blue;
  margin-top: 20px;

  ul {
    margin-left: 16px;
  }
  li {
    list-style: disc;
  }
}
