.withDraw-form {
  .box-card-top {
    .top-left {
      @include animation-transform;
      @include rtl-sass-value(transform, translate(300px, 0px), translate(-300px, 0px));
      padding: 0px 60px;
      min-width: 600px;

      .labelTitle label {
        color: $primary;
      }

      .labelTitle label,
      .labelTitle .row,
      /deep/.el-form-item__label {
        font-size: 16px;
      }

      .amount {
        /deep/ {
          .el-input__inner {
            font-size: 30px;
          }
          .el-form-item__label {
            margin-bottom: 10px;
            text-transform: inherit;
          }
        }
      }
      &.left-style {
        transform: translate(0px, 0px);
      }
    }
  }
}

.continue-btn {
  margin-top: 68px;
  /deep/ .el-button {
    text-transform: inherit;
    width: 180px;

    &:hover {
      color: #fff;
    }
  }
}
@media (min-width: 1201px) {
  .withDraw-form {
    .box-card-top {
      .top-left.left-style {
        .form-button.continue-btn {
          text-align: end !important;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .withDraw-form {
    .box-card-top {
      .top-left {
        padding: 0px 20px;
        min-width: 440px;
        @include rtl-sass-value(transform, translate(200px, 0px), translate(-200px, 0px));
        &.left-style {
          transform: translate(0px, 0px);
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .withDraw-form {
    .box-card-top {
      .top-left {
        min-width: 600px;
        @include rtl-sass-value(transform, translate(0px, 0px), translate(0px, 0px));

        &.left-style {
          .form-button.continue-btn {
            text-align: center !important;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .withDraw-form {
    .box-card-top {
      .top-left {
        min-width: 480px;
      }
    }
  }
}

@media (max-width: 550px) {
  .withDraw-form {
    .box-card-top {
      .top-left {
        min-width: 350px;
      }
    }
  }
}

@media (max-width: 370px) {
  .withDraw-form {
    .box-card-top {
      .top-left {
        min-width: 220px;
      }
    }
  }
}
