/deep/ .plain-input .el-input__inner {
  height: auto;
  line-height: normal;
  padding: 12px 0;
  border: 0;
  background: none;
  color: #000d28;
  border-bottom: 1px solid #cccfd4;
  border-radius: 0;

  &::placeholder {
    color: #d8d8d8;
    font-size: 16px;
  }
}

/deep/ .el-form-item.is-error .plain-input .el-input__inner {
  border: none !important;
  border-bottom: 1px solid $light-red !important;
}
