.credit-card-group {
  margin-top: 10px;
}
.credit-card-tab {
  padding: 20px 24px;
  background: $white;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.credit-card-container {
  margin-top: 28px;
}

.item-check {
  display: none;
}

.credit-card-tab {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.main-content {
  width: calc(100% - 136px);
  display: flex;
  flex-direction: column;
}

.credit-card-icon {
  height: 40px;
  width: 76px;
  img {
    height: 100%;
  }

  &.visa {
    background-image: url(~@/assets/images/withdraw/visa_icon.png);
    background-position: left;
    background-size: auto 24px;
    background-repeat: no-repeat;
  }

  &.master-card {
    background-image: url(~@/assets/images/withdraw/master_card_icon.png);
    background-position: left;
    background-size: auto 40px;
    background-repeat: no-repeat;
  }
}

.credit-card-selection {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;

  /deep/ .el-select {
    margin-left: 4%;
  }
}

.tab-text {
  line-height: 40px;
  white-space: nowrap;
  // margin-right: 4%;
  display: flex;
  align-items: center;
  font-size: 16px;

  & + .tab-text {
    margin-left: 8%;
  }
}
.spacer {
  width: 60px;
  height: 100%;
  @media (max-width: 1400px) {
    width: 24px;
  }
}

.left-part {
  width: 100%;
  height: 40px;
  display: flex;
}

.right-part {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
  margin-top: 4px;
}

.amount-number {
  font-weight: 900;
  font-size: 16px;
  line-height: 40px;
}

.select-icon {
  width: 40px;
  height: 40px;
  background-image: url(~@/assets/images/withdraw/select-down-circle.png);
  background-position: center;
  background-size: 40px;
  cursor: pointer;

  &.open {
    background-image: url(~@/assets/images/withdraw/select-up-circle.png);
  }
}

@media (min-width: 1201px) {
  .credit-card-container {
    margin-top: 20px;
  }
  .main-content {
    flex-direction: row;
  }

  .right-part {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .right-part {
    flex-wrap: wrap;
    justify-content: space-between;

    .tab-text + .tab-text {
      margin-left: 0;
    }
  }

  .tab-text {
    font-size: 12px;
  }
  .amount-number {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .credit-card-tab {
    flex-direction: column;
    justify-content: left;
    padding: 24px 8px 16px 8px;
  }

  .main-content {
    width: 100%;
  }

  .spacer {
    width: 8px;
  }
}
